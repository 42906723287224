export default [{
    path: '/layout',
    component: () => import('@/view/deal'),
    redirect: '/',
    children: [{
        path: '/',
        component: () => import('@/view/deal/components/Main/Main.vue'),
        children: [
        ]
    },
    {
        path: '/publish/price',
        component: () => import('@/view/Publish/price'),
        meta: {
            title: '发布价格'
        }
    },
    {
        path: '/publish/business',
        component: () => import('@/view/Publish/business'),
        meta: {
            title: '发布商机'
        }
    },
    {
        /* 最新报价模块 */
        path: '/new-offer',
        component: () => import('@/view/NewPrice'),
        redirect: '/new-offer/all',
        children: [{
            path: '/new-offer/all',
            component: () => import('@/view/NewPrice/RoutesView'),
            meta: {
                title: '最新报价'
            },
        },
        {
            path: '/new-detail/:id',
            component: () => import('@/view/NewPrice/RoutesView/Detail/detail.vue'),
            meta: {
                title: '报价详情'
            },
        },
        {
            path: '/offer-search',
            name: 'search-offer',
            component: () => import('@/view/NewPrice/RoutesView'),
            meta: {
                title: ''
            },
        }
        ]
    },
    {
        path: '/trade',
        component: () => import('@/view/Trade/trade.vue'),
        meta: {
            title: '贸易商机'
        },
        redirect: '/trade/new',
        children: [{
            path: '/trade/new',
            name: 'trade_new',
            component: () => import('@/view/Trade/RoutesView/NewTrade.vue'),
        },
        {
            path: '/trade/detail/:id',
            component: () => import('@/view/Trade/RoutesView/Detail/detailIndx.vue'),
            meta: {
                title: '贸易商机详情'
            },
        }
        ]
    },
    {
        path: '/Recommend',
        component: () => import('@/view/Recommend/index.vue'),
        meta: {
            title: '企业推荐'
        },
        redirect: '/recommend/company',
        children: [{
            path: '/recommend/company',
            component: () => import('@/view/Recommend/RoutesView/ShowContainer.vue'),

        }]
    },
    {
        path: '/steel/dynamic',
        component: () => import('@/view/steelDynamic'),
        meta: {
            title: '钢市动态'
        },
        redirect: '/steel/dynamic/show',
        children: [{
            path: '/steel/dynamic/show',
            name: 'showSteelDynamic',
            component: () => import('@/view/steelDynamic/RoutesView/steelDynamicMain.vue'),
        },
        {
            path: '/steel/detail/:id',
            name: 'steel_detail',
            component: () => import('@/view/steelDynamic/Detail/detail.vue'),
        }
        ]
    },
    {
        path: '/meeting/deatai/:id',
        name: 'MeetingDeatai',
        component: () => import('@/view/MeetingDeatai/index'),
        meta: {
            title: '会议展会'
        }
    },
    {
        path: '/about',
        component: () => import('@/view/help'),
        meta: {
            title: '关于平台'
        },
        redirect: '/help/introduce',
        children: [
            //规则
            {
                path: "/help/introduce",
                name: "Introduce", //平台介绍
                meta: {
                    title: '平台介绍'
                },
                component: () => import("@/view/About/index.vue"),
            },
            {
                path: "/help/notice",
                name: "Notice", //平台公告
                meta: {
                    title: '平台公告'
                },
                component: () => import("@/view/help/notice/index.vue"),
            },
            {
                path: "/help/notice/:id",
                name: "NoticeDetail", //公告详情
                meta: {
                    title: '公告详情'
                },
                component: () => import("@/view/help/notice/Detail.vue"),
            },
            {
                path: "/help/general",
                name: "HelpGeneral",
                meta: {
                    title: '平台总则'
                },
                component: () => import("@/view/help/rule/General.vue"),
            },

            {
                path: "/help/agreement",
                name: "HelpAgreement",
                meta: {
                    title: '用户协议'
                },
                component: () => import("@/view/help/rule/Agreement.vue"),
            },
            {
                path: "/help/privacy",
                name: "HelpPrivacy",
                meta: {
                    title: '隐私政策'
                },
                component: () => import("@/view/help/rule/Privacy.vue"),
            },
            {
                path: "/collect/company",
                name: "collect-company",
                meta: {
                    title: '收藏的公司'
                },
                component: () => import("@/view/help/Collect/CollectCompany.vue"),
            },
            {
                path: "/collect/product",
                name: "collect-product",
                meta: {
                    title: '收藏的产品'
                },
                component: () => import("@/view/help/Collect/CollectProduct.vue"),
            },
            {
                path: "/collect/ezine",
                name: "collect-ezine",
                meta: {
                    title: '收藏的杂志'
                },
                component: () => import("@/view/help/Collect/CollectEzine.vue"),
            },
            {
                path: "/help/contact",
                name: "Contact", //联系我们
                meta: {
                    title: '联系我们'
                },
                component: () => import("@/view/help/contact/index.vue"),
            },
        ]
    },

    {
        path: '/price/market',
        name: 'price_market',
        component: () => import('@/view/price_hq'),
        redirect: '/price/market/index',
        meta: {
            title: '价格行情'
        },
        children: [
            {

                path: '/price/market/index',
                name: '/price_market_index',
                component: () => import('@/view/price_hq/price_hq.vue'),

            },
            {
                path: '/market/detail/:id',
                name: 'market_detail',
                component: () => import('@/view/price_hq/detail.vue'),
            }
        ]

    },


    /* 企业库申请 */
    {
        path: '/enterprise',
        name: 'enterprise',
        component: () => import('@/view/enterprise/index.vue'),
        meta: {
            title: '申请入驻'
        },
    }
    ]
},
{
    /* 公司的详情页 */
    path: '/:company/company/detail',
    component: () => import('@/view/companyDetail/companyDetail.vue'),
    children: [{
        path: '/:company/companyDetail/home',
        component: () => import('@/view/companyDetail/Home/home.vue')
    },]
}, {
    path: '/ezine',
    component: () => import('@/view/EZine'),
},
{
    name: 'demo',
    path: '/demo',
    component: () => import('@/Demo/index.vue')
},
{
    path: '/404',
    component: () => import('@/view/404/index.vue')
}
]