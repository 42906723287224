import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-665a260c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ws_login" }

import { $apiWxLoginAppid, $apiWxLoginAttest } from "@/api/modules/login";
import { ref, reactive, getCurrentInstance } from "vue";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";

export default {
  props: {
  whereLoin: String,
},
  emits: "onLogin",
  setup(__props, { emit: $emits }) {

const props = __props

const route = useRoute(),
  router = useRouter();
const {
  proxy: { $msg },
} = getCurrentInstance();

function urlencode(str) {
  str = (str + "").toString();
  return encodeURIComponent(str)
    .replace(/!/g, "%21")
    .replace(/'/g, "%27")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/\*/g, "%2A")
    .replace(/%20/g, "+");
}
// 获取appid

async function getWxLoginAppid() {
  const { status, data, msg } = await $apiWxLoginAppid();
  if (status) {
    // 自己生成二维码登录
    // <div id="login_container"></div>
    // new WxLogin({
    //   self_redirect:false,
    //   id:"login_container",
    //   appid:data.appId,
    //   scope:data.scope,
    //   redirect_uri: urlencode("https://gxb.cn/#/other/login"),
    //   state:data.state,
    // });

    window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${
      data.appId
    }&redirect_uri=${encodeURIComponent("https://gxb.cn/wechat/code")}&scope=${
      data.scope
    }&state=${encodeURIComponent(
      // 扫描二维码 登录成功后跳转的地址  tzc标注
      props.whereLoin == "deal"
        ? `http://bxgb2b.com/#/${route.path}`
        : "http://bxgb2b.com/"
    )}`;
  } else {
    $msg.warn(msg);
  }
}



// 登陆验证
wxLoginAttest();
async function wxLoginAttest() {
  console.log(route);
  if (!route.query.code) return;
  // if(!router.options.history.base.includes("code="))return;
  // if(!router.options.history.base.split("code=")[1])return;
  Toast.loading({
    message: "正在登录...",
    overlay: true,
    forbidClick: false,
    duration: 0,
  });
  const params = { code: route.query.code, state: route.query.state };
  const { status, data, msg } = await $apiWxLoginAttest(params);
  if (status) {
    if (data.param) {
      $msg.warn("您需要绑定手机号才能使用微信登录！"),
        Toast.clear(),
        router.push({
          path: "/bind/phone",
          query: {
            unionId: data.param,
            path: props.whereLoin == "deal" ? route.path : "",
          },
        });
    } else {
      $emits("onLogin", data.token);
      Toast.clear();
    }
  } else {
    $msg.warn(msg);
    Toast.clear();
  }
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

}