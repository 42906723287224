// =>过滤字符长度
export function subStrVal(val, num, cont = '', msg = "暂无内容...") {
  if (val) {
    if (val.length > num) {
      return val.substr(0, num) + cont
    } else {
      return val
    }
  } else {
    return msg
  }
}
// =>过滤头像
export function concatImg(val, name = "avatar", type) {
  if (val) {
    return "https://cdn.gangxinbao.cn/" + val
  }
  if (!val) {
    if (name == "shop") {
      if (type) {
        // 分类 
        let shopType = [1, 3, 24, 21, 67, 28, 30, 26, 27]
        let shopUrl = ['rzj', 'zhb', 'gjc', 'hxg', 'bg', 'wfg', 'dxg', 'fg', 'lxg']
        if (shopType.includes(type.type1) || shopType.includes(type.type2)) {
          if (shopType.indexOf(type.type1) !== -1) {
            return `https://cdn.gangxinbao.cn/default/${shopUrl[shopType.indexOf(type.type1)]}.jpg`
          }
          if (shopType.indexOf(type.type2) !== -1) {
            return `https://cdn.gangxinbao.cn/default/${shopUrl[shopType.indexOf(type.type2)]}.jpg`
          }
        }
      }
      return require("../assets/defaultCover.jpg")
    }
    if (name == "cover") {
      return require("../assets/defaultCover.jpg")
    }
    if (name == "avatar") {
      return require("../assets/default1.jpg")
    }
    if (name == 'logo') {
      return require('../assets/company/default_company_logo.png')
    }
    if (name == 'gxbLogo') {
      return require('../assets/gxb_logo.png')
    }
  }
}


// => 主营产品
export function concatMain(main, key) {
  if (!main.length) {
    return '暂无'
  }
  if (main.length == 1) {
    return main[0][key]
  }
  let mainCont = ''
  for (let index = 0; index < main.length; index++) {
    mainCont += main[index][key] + '、'
  }
  return mainCont.substring(0, mainCont.length - 1)
}



// => 订单状态 user:buy||pay
export function orderStatus(type, user) {
  switch (type) {
    case 0:
      return "未付款";
    case 1:
      return "已付款";
    case 2:
      return "已发货";
    case 3:
      return "已签收";
    case 4:
      return user == 'buy' ? "退款申请" : '退货申请';
    case 5:
      return user == 'buy' ? "退款中" : '退货中';
    case 6:
      return user == 'buy' ? "已退款" : "已退货";
    case 7:
      return "交易已取消";
    case 8:
      return "未付尾款";
    case 9:
      return "交易完成";
    case 10:
      return "已超时";
  }
}
// => 商铺短名
export const COM_NAME = new Map([
  ['zjcf', "浙江乘风"],
  ['zjgy', "浙江高阳"],
  ['zjht', "杭州恒通"],
  ['zjjh', "浙江津华"],
  ['zjwg', "浙江万钢"],
  ['zjgf', "浙江港丰"],
  ['zjsd', "浙江三丹"],
  ['hzjy', "杭州骏赢"],
  ['hzhg', "杭州汉钢"],
  ['zjtz', "浙江同振"],
  ['zjtr', "浙江泰瑞安"],
  ['zjjy', "杭州久赢"],
  ['hzzh', "杭州正和"],
  ['njkr', "南京坤润"],
])