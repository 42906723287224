/* import {
  $apiTypesList
} from "@/api/modules/steward"; */
/* import {
  $apiShopCarCount,
  $apiSteelTypeList,
} from "@/api/modules/store" */
import {
  ElMessage
} from "element-plus";
const actions = {
  // 获取公司钢材分类
  /*  async getSteelTypeList(context, params) {
     // const { status, data, msg } = await $apiSteelTypeList(params)
     // status ? context.commit('setSteelType', data) : ElMessage.error(msg)
   }, */
  // 获取购物车数量
  /*   async getShopCarCount(context, params) {
      const {
        status,
        data,
        msg
      } = await $apiShopCarCount({
        token: params
      })
      status ? context.commit('setShopCarCount', data) : ElMessage.error(msg)
    }, */
  // 获取钢材分类
  /* async getTypeList(context) {
    const {
      status,
      data
    } = await $apiTypesList();
    status ? context.commit('setSteelType', data) : ElMessage.error(msg)
  }, */
  // 获取钢材分类
  /*   async sendCode(context) {
      const {
        status,
        data
      } = await $apiTypesList();
      status ? context.commit('setSteelType', data) : ElMessage.error(msg)
    } */
}
export default actions