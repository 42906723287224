
import axios from "axios";
const baseURL2 = process.env.NODE_ENV === 'development' ? 'http://192.168.2.108/api' : 'https://api.bxgb2b.com/api'
// axios.js文件配置
// 自定义实例默认值
const axiosInstance = axios.create({
    baseURL: 'https://api.bxgb2b.com/api',
    timeout: 20000, // 如果请求花费了超过 `timeout` 的时间，请求将被中断
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(function (config) {
    config.headers['Access-Control-Allow-Origin'] = '*'
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default axiosInstance