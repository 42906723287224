
export const drag = {
    mounted(el) {
        let oDiv = el
        // 获取元素的 宽度
        let width = oDiv.offsetWidth
        let height = oDiv.offsetHeight
        oDiv.onmousedown = function (e) {
            let {
                zoom
            } = oDiv.dataset
            //  鼠标按下，计算当前元素距离可视区的距离
            let disX = e.clientX - oDiv.offsetLeft
            let disY = e.clientY - oDiv.offsetTop;
            console.log(oDiv.offsetLeft, oDiv.offsetTop);
            if (zoom != 1) {
                document.onmousemove = function (e) {
                    //  获取到鼠标拖拽后的f横向位移(距离父级元素)        
                    let l = e.pageX - disX
                    //  获取到鼠标拖拽后的纵向位移(距离父级元素)
                    let t = e.pageY - disY
                    oDiv.style.left = l + 'px'
                    oDiv.style.top = t + 'px'

                }
            }

            //    松开事件后，移除事件
            document.onmouseup = function () {
                document.onmousemove = null
                document.onmouseup = null
            }
        }
    }
}