import http from "./http"
export const $apiWxLoginAppid = (data) => http({
    method: 'post',
    url: '/wechat/params',
    params: data
}) /* 获取参数 */
export const $apiWxLoginAttest = (data) => http({
    method: 'post',
    url: '/wechat/login',
    params: data
}) /* 登录验证是否绑定手机号 */
export const $apiWxLoginBindPhone = (data) => http({
    method: 'post',
    url: '/bind/phone',
    params: data
}) /* 绑定手机 */


export const $logOut = data => http({
    method: 'post',
    url: '/log/lot',
    params: data
})