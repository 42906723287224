

import {
  $apiUserInfo,
  $apiCompanyInfo,
  $apiUserPermissions
} from "@/api/modules/user";

import {
  ElMessage
} from "element-plus";
const UserStore = {
  state: {
    userInfo: {},
    companyInfo: {},
  },
  mutations: {
    setUserInfo(state, params) {
      state.userInfo = params.data
      localStorage.setItem("user_info", JSON.stringify(params.data))
      params.reload && params.reload()
    },
    setCompanyInfo(state, data) {
      state.companyInfo = data
    }
  },
  actions: {
    async getUserInfo(context, params) {
      const {
        status,
        data,
        msg
      } = await $apiUserInfo({
        token: params.token
      })
      if (status === 1) {
        /*  if (data.company_id) {
           context.dispatch('getCompanyInfo', data.company_id)
         } */
        status ? context.commit("setUserInfo", {
          data: data,
          reload: params.reload
        }) : ElMessage.error(msg);
      } else {
        // 发生了其他错误
        ElMessage.warning(msg)
        setTimeout(() => {
          localStorage.clear()
          window.location.reload()
        }, 300);
      }

    },
    async getCompanyInfo(context, id) {
      const {
        status,
        data,
        msg
      } = await $apiCompanyInfo({
        id: id
      })
      status ? context.commit("setCompanyInfo", data) : ElMessage.error(msg);
    },
  }
}
export default UserStore