import http from "./http"

// 个人信息(登录) {token}
export const $apiUserInfo = (data) => http({
  method: "post",
  url: "/user/update/info",
  params: data,
})

/** 个人卡片信息=> @param=> {id} */
export function $apiUserCard(data) {
  return http({
    method: "get",
    url: `/userCard?id=${data}`,
    // params: data,
  })
}

// 公司信息 data{id}
export const $apiCompanyInfo = (data) => http({
  method: "post",
  url: "/company/info",
  params: data,
});


// # data{companyId}   是否是联盟商家
export const $apiUserPermissions = (data) => http({
  method: "get",
  url: "/if/union/company",
  params: data,
});

// # admin 是否是管理员
export const $apiAdminPermissions = (data) => http({
  method: "post",
  url: "/company/permissions",
  params: data,
});

// 个人信息修改
export const $editUserInfo = (data) => http({
  method: "post",
  url: "/user/update",
  params: data,
})

export const $subUserOrder = (data) => http({
  method: "post",
  url: "/user/goods/order",
  params: data,
})