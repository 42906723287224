import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

import ServiceChat from "./components/me/ServiceChat.vue";
import WebSocket from "./view/chat/WebSocket.vue";
import { provide, ref, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoginPopup from "./view/login/LoginPopup.vue";
import axios from "@/api/axios.js";
import axios1 from 'axios'
/* import { centerUrl } from "@/constant/centerUser.js"; //个人中心网址 */

export default {
  setup(__props) {

const isShowHeadAdv = ref(true) // 展示头部的广告位置?
axios1.get('https://gangxinbao.cn/api/unified/mourning').then(res => {
    if (res.status === 200 && res.data.status === 1) {
        console.log("I don't want you to read this")
        document.documentElement.style.filter = 'grayscale(1)'
    }
})
const router = useRouter();
const route = useRoute();
let view_show = ref(true);
let login_show = ref(false);
let socket_show = ref(false);
let socket_chatId = ref("");
provide("login_show", loginShow);
function loginShow() {
    login_show.value = !login_show.value;
}
const token = localStorage.getItem("token") || "";
provide("openSocket", openSocket);
function openSocket(pid) {
    socket_chatId.value = pid || "";
    if (socket_show.value) {
        socket_show.value = false;
        setTimeout(() => {
            socket_show.value = true;
        }, 500);
    } else {
        socket_show.value = true;
    }
}
provide("reload", reloadViews);
async function reloadViews() {
    view_show.value = false;
    await nextTick();
    view_show.value = true;
}

return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (!_unref(token))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(LoginPopup, {
            showLogin: _unref(route).query.code || _unref(login_show)
          }, null, 8, ["showLogin"])
        ]))
      : _createCommentVNode("", true),
    (_unref(socket_show))
      ? (_openBlock(), _createBlock(WebSocket, {
          key: 1,
          chatId: _unref(socket_chatId),
          onClose: _cache[0] || (_cache[0] = $event => (_isRef(socket_show) ? socket_show.value = false : socket_show = false))
        }, null, 8, ["chatId"]))
      : _createCommentVNode("", true),
    (!_unref(route).meta.socket)
      ? (_openBlock(), _createBlock(ServiceChat, {
          key: 2,
          chatId: _unref(socket_chatId)
        }, null, 8, ["chatId"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

}