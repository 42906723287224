// 设置面包屑标题的方法
const SET_Breadcrumb = (arr = [], params) => {
    let index = arr.findIndex(route => route.path.includes('offer-search'))
    if (index !== -1) {
        arr[index].meta.title = `${params.isChnageBreadcrumb}`
    }
}

export const configRouter = (router) => {
    router.beforeEach((to, form, next) => {
        if (to.params.isChnageBreadcrumb) {
            SET_Breadcrumb(to.matched, to.params)
        }
        next()
    })
}