
import axios from "axios";
// axios.js文件配置
// 自定义实例默认值
const axiosInstance = axios.create({
  //   http://192.168.1.7/b2b/public/index.php/api/   https://gangxinbao.top/
  baseURL: 'http://gangxinbao.top/api', // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
  baseURL:'https://gangxinbao.cn/api', // 正式坏境
  timeout: 20000, // 如果请求花费了超过 `timeout` 的时间，请求将被中断
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(function (config) {
  config.headers['Access-Control-Allow-Origin'] = '*'
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default axiosInstance