import { useCssVars as _useCssVars, unref as _unref } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { inject, onMounted, ref } from "vue";

export default {
  props: {
  chatId: [String, Number],
  pos_btm: { type: String, default: "120px" },
},
  setup(__props) {

const props = __props

_useCssVars(_ctx => ({
  "c56ee316": (__props.pos_btm)
}))


const openSocket = inject("openSocket");
const login_show = inject("login_show");

const openChat = () => {
  /* 客服 */
  return openSocket();
  /* 非客服 */
  if (localStorage.getItem("token")) {
    openSocket(props.chatId);
  } else {
    login_show();
  }
};
let chatRef = ref();

onMounted(() => {
  if (document.body.clientWidth < 1320) chatRef.value.style.right = 10;
  else chatRef.value.style.right = "calc(50% - 720px)";
});

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "open-chat",
    ref_key: "chatRef",
    ref: chatRef
  }, null, 512))
}
}

}